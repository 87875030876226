/**
 * 登录登出模块接口
 */
import axiosApi from '../../modules/axios-api'
import { LOGIN_MICRO_SERVICE_PREFIX } from '../../index'

export default {
  queryT (data) {
    return axiosApi.get(`${LOGIN_MICRO_SERVICE_PREFIX}/getSsoAuthUrl`, { clientLoginUrl: data.clientLoginUrl }, { isAuth: false })
  },
  login (data) {
    return axiosApi.get(`${LOGIN_MICRO_SERVICE_PREFIX}/doLoginByTicket`, { ticket: data.ticket }, { isAuth: false })
  },
  logout (params) {
    return axiosApi.get(`${LOGIN_MICRO_SERVICE_PREFIX}/sso/logout`, { authToken: params })
  },
  isLogin () {
    return axiosApi.get(`${LOGIN_MICRO_SERVICE_PREFIX}/sso/isLogin`)
  }
}
